import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Conference } from "./Webinar/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as EvenementGenerique } from "./EvenementGenerique/Form"
import { default as JourneesDecouverte } from "./JourneesDecouverte/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const ConferenceEpita = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Conference />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationEpita = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const EvenementGeneriqueEpita = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <EvenementGenerique />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesDecouverteEpita = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesDecouverte />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesEpita = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { ConferenceEpita, DocumentationEpita, EvenementGeneriqueEpita, JourneesDecouverteEpita, JourneesPortesOuvertesEpita }
