import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Atelier } from "./Atelier/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as Precandidature } from "./Candidature/Form"
import { customTheme } from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const DocumentationIAInstitut = ({ elevation, primaryColor, sx }) => {
  const theme = customTheme(primaryColor)
  return (
    <FormContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Forms elevation={elevation} sx={sx}>
              <CssBaseline />
              <Documentation />
            </Forms>
          </ThemeProvider>
        </StyledEngineProvider>
    </FormContextProvider>
  )
}

const AtelierIAInstitut = ({ elevation, primaryColor, sx }) => {
  const theme = customTheme(primaryColor)
  return (
    <FormContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Forms elevation={elevation} sx={sx}>
              <CssBaseline />
              <Atelier />
            </Forms>
          </ThemeProvider>
        </StyledEngineProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesIAInstitut = ({ elevation, primaryColor, sx }) => {
  const theme = customTheme(primaryColor)
  return (
    <FormContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Forms elevation={elevation} sx={sx}>
              <CssBaseline />
              <JourneesPortesOuvertes />
            </Forms>
          </ThemeProvider>
        </StyledEngineProvider>
    </FormContextProvider>
  )
}

const PrecandidatureIAInstitut = ({ elevation, primaryColor, sx }) => {
  const theme = customTheme(primaryColor)
  return (
    <FormContextProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Forms elevation={elevation} sx={sx}>
            <CssBaseline />
            <Precandidature />
          </Forms>
        </ThemeProvider>
      </StyledEngineProvider>
    </FormContextProvider>
  )
}

AtelierIAInstitut.propTypes = {
  elevation: PropTypes.number,
  primaryColor: PropTypes.string,
  sx: PropTypes.object
}

PrecandidatureIAInstitut.propTypes = {
  elevation: PropTypes.number,
  primaryColor: PropTypes.string,
  sx: PropTypes.object
}

export { AtelierIAInstitut, DocumentationIAInstitut, JourneesPortesOuvertesIAInstitut, PrecandidatureIAInstitut }
