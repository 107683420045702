import { createTheme } from "@mui/material/styles"
import { defaultTheme } from "components/Theme/Style"

const customTheme = (color, backgroundColor, hover, textColor, buttonColor, buttonTextColor, selectHover, elevation, borderRadius, shadow) => {
    let themeOptions = {
        palette: {
            mode: 'light',
            primary: { main: '#102B65' },
            secondary: { main: '#ff4d4d' },
            neutral: { main: '#5c6ac4' }
        }
    }

    if (color !== '#FFF') {
        themeOptions = {
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: backgroundColor,
                            elevation: elevation,
                            borderRadius: borderRadius,
                            boxShadow: shadow,
                        }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: color, // Couleur de la bordure
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: textColor, // Couleur du label
                            },
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        icon: {
                            color: color, // Couleur de l'icone
                        },
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: color,
                                    '&:hover': {
                                        borderColor: hover,
                                    },
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: textColor,
                            },
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: backgroundColor,
                                '&:hover': {
                                    backgroundColor: hover,
                                },
                            },
                            '&:hover': {
                                backgroundColor: selectHover,
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: color,
                            '&:hover': {
                                color: hover,
                            },// Couleur de la bordure
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            color: textColor, // Couleur du label
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            color: color, // Couleur de l'icône
                        },
                    },
                },
                MuiRadio: {
                    styleOverrides: {
                        root: {
                            color: color, // Couleur de l'icône
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: buttonColor,
                            color: buttonTextColor,
                            '&:hover': {
                                backgroundColor: hover,
                            },

                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            color: color, //color helpertext
                        }
                    }
                },
                MuiTelInput: {
                    styleOverrides: {
                        input: {
                            color: textColor,
                            '& .MuiInputBase-input': {
                                color: textColor,
                            },
                        },
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: color,
                                    '&:hover': {
                                        borderColor: hover,
                                    },
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: textColor,
                            },
                        },
                    },
                },
            },
            palette: {
                mode: 'light',
                primary: { main: color, dark: color },
                secondary: { main: '#ff0000' },
                neutral: { main: '#5c6ac4' },
                text: { primary: textColor },
                error: { main: '#FF5733' }
            }
        }
    }

    return createTheme(defaultTheme, themeOptions)
}

export { customTheme }


//bouton classique mui 
//==> ajouter bouton pour utiliser des icones propres à nous
// text OK ohone code KO
// see to add our selection arrows 
