import { AcademicLevel, Availabilities, CampaignId, Email, FirstName, LastName, MobilePhoneRequired, SlotId } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  ...Availabilities,
  ...CampaignId,
  consent: Yup.string().required('Veuillez faire un choix'),
  lead: Yup.object().shape({
    ...AcademicLevel,
    ...Email,
    ...FirstName,
    ...LastName,
    ...MobilePhoneRequired
  }),
  ...SlotId
})

export default schema
