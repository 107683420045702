import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campaigns, Campus, Consentement, Disponibilites, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { APPOINTMENT, CALL_PHONE, campaigns, defaultValues, getAcademicLevels, salutations } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      lead: {
        ...defaultValues,
        PostalCode: '',
        Salutation: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId

    const defaultDataLead = {
      Company: lead.FirstName + ' ' + lead.LastName,
      Is_From_Web__c: true,
      LeadSource: campaignId !== 'unavailable' ? 'Conférence' : 'Demande de rendez-vous',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== 'unavailable') {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: data.status
        }
        await salesforce.createCampaignMember(campaignMember)
        document.location.href=`${process.env.REACT_APP_SUPINFO_URL}/inscription-conference/confirmation/?campus=${campus}&campaignId=${campaignId}`
      } else {
        let description = ''

        if (data.availabilities.type === 'Être rappelé(e) sur Skype') {
          description = 'ID Skype : ' + data.availabilities.skypeId + '\n\n'
        }
        description += data.availabilities.dates.join(', ')

      const subject = data.availabilities.type
        const task = {
          ActivityDate: new Date(),
          Description: description,
          OwnerId: leadCreated.OwnerId,
          Subject: subject,
          WhoId: leadCreated.Id
        }

        await salesforce.createTask(task)
        document.location.href=`${process.env.REACT_APP_SUPINFO_URL}/confirmation-entretien/?campus=${campus}`
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const campus = watch('lead.Campus__c')
  // useEffect
  useSalesforce('SUPINFO', true)

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" />
        </Grid>
        <AcademicLevel options={getAcademicLevels('SUPINFO')} section={false} specialites={false} />
        <Grid item xs={12}>
          <InputText control={control} label="Code postal" name="lead.PostalCode" />
        </Grid>
        <Campus label="Campus souhaité" />
        <Campaigns eda={true} limit={4} school="Supinfo" text="à la conférence" type="Conférence" />
        <Disponibilites availabilities={[{ label: CALL_PHONE, value: CALL_PHONE }, { label: APPOINTMENT , value: APPOINTMENT }]} eda={true} text="les conférences" />
        <Consentement form="Conference" school="SUPINFO" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
      </Grid>
    </FormProvider>
  )
}

export default Form
