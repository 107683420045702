import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Adresse, Campus, ChoixDocumentation, Consentement, ConsentementMultiple, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { adresse, consent, consentement, defaultValues, getAcademicLevels, getCampus, salutations, specialities } from "services"
import MentionsLegales from "components/MentionsLegales"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...consent,
      lead: {
        ...defaultValues,
        ...consentement,
        ...adresse,
        Salutation: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, watch } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const defaultDataLead = {
      Campus__c: 'Paris',
      Division__c: 'EPITA IA Institut',
      IONISSchool__c: 'EPITA IA Institut',
      LastForm__c: 'Documentation PDF',
      LeadSource: 'Internet: Demande de doc',
      Paper_doc_request__c: choix === 'recevoir',
      PDFdownloaded__c: choix === 'telecharger',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      await salesforce.createLead(finalLead)
      document.location.href=`https://www.ia-institut.fr/ecole-intelligence-artificielle/retour-demande-documentation/?niveau=${encodeURIComponent(lead.AcademicLevel__c)}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const choix = watch('choix')
  // useEffect
  useSalesforce('EngineeringSchoolLead')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <ChoixDocumentation />
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel options={getAcademicLevels('EPITA IA Institut')} school="EPITA IA Institut" section={false} specialites={false} />
        {choix === 'recevoir' && (
          <Adresse />
        )}
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Documentation" school="EPITA IA Institut" />
      </Grid>
    </FormProvider>
  )
}

export default Form
