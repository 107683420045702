import { useState } from "react"

const { StepLabel, Step, Stepper } = require("@mui/material")

const Navigation = ({ activeStep, steps }) => {

  // const [activeStep, setActiveStep] = useState(0)

  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 2, pb: 2, mb: 2 }}>
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel
              sx={{
                '& .MuiStepIcon-root.Mui-completed': {
                  color: '#a7d300', // Couleur verte pour les étapes validées
                },
              }}
            >{label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default Navigation
