import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields";
import { AcademicLevel, Consentement, RequiredFields, Submit, Cursus, Campus, ConsentementMultiple } from "components";
import { useForms, useSalesforce } from "hooks";
import { defaultValues, getAcademicLevels, salutations, getCampus } from "services";
import schemas from "./Validation";
import { DevTool } from '@hookform/devtools';
import queryString from "query-string"
import Formations from './components/multiSchoolFormations';
import MultiCampus from './components/multiCampus'
import MentionsLegales from "components/MentionsLegales"
import getRecordTypeName from "./services/functions"
import Presentiel from './components/presentiel';

const Form = ({
    division,
    campuses,
    school,
    lastForm, leadSource, defaultCampaignId, status, form, academics, formationRequired, presentielRequired,
    theme, firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, academicLevelRequired, campusRequired, postalCodeRequired, isMultiCampus, contactConsent,showEndIcon,
    consentRequired, legalRequired, salutationRequired, redirectUrl, campMemberStatus }) => {
    const { recordTypeId, salesforce, setOpenSnackbar } = useForms();

    


    let cursus = {};
    let eda = false;
    if (school === "SUPINFO" || school === "EPITECH DIGITAL") {
        eda = true;
    } else {
        cursus = {
            Apprentissage__c: false,
            Bachelor__c: false,
            CycleInge__c: false,
            Master__c: false,
            Wished_Programm__c: '',
            Salutation: '',
            PostalCode: '',
        };
    }

    const requiredFields = {
        firstNameRequired,
        lastNameRequired,
        emailRequired,
        phoneNumberRequired,
        academicLevelRequired,
        campusRequired,
        contactConsent
    };

    const methods = useForm({
        defaultValues: {
            formations: [],
            lead: {
                ...defaultValues,
                ...cursus,
            },
        },
        context: {
            ...requiredFields
        },
        resolver: yupResolver(schemas)
    });

    const { control, handleSubmit, setValue, watch, reset } = methods;
    const { campaignId, utm_id, campaignid, campaign_id } = queryString.parse(document.location.search)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const presentielValue = watch('onSite');
    const wishedProgram = watch('lead.Wished_Programm__c');
    const campusOptions = getCampus(school) && getCampus(school).length > 0 ? getCampus(school) : campuses;

    
    

    const onSubmit = async (data) => {
        setOpenSnackbar(false);
        setShowConfirmation(true);
        setTimeout(() => {
            setShowConfirmation(false);
        }, 20000);
        const lead = data.lead;
        if (Array.isArray(lead.Campus__c)) {
            lead.Campus__c = lead.Campus__c.join(';');
        }
        let formParams = {};
        if (!eda) {
            formParams = {
                Division__c: division,
                IONISSchool__c: school,
                LastForm__c: lastForm,
            }
            if (school === 'e-artsup') {
                formParams['Wished_Programm__c'] = wishedProgram;
            }
        } else {
            formParams = {
                Company: lead.FirstName + ' ' + lead.LastName,
                Is_From_Web__c: true,
            }
        }

        const defaultDataLead = {
            LeadSource: leadSource,
            Status: status,
            RecordTypeId: recordTypeId,
            ...formParams,
        }
        const finalLead = { ...lead, ...defaultDataLead };
        try {
            const createdLead = await salesforce.createLead(finalLead);
            let campaignMember = {
                CampaignId: defaultCampaignId,
                LeadId: createdLead.Id,
                Status: campMemberStatus,
            }

            if (presentielValue === 'true') {
                const presentielTask = {
                    ActivityDate: new Date(),
                    Description: "Le prospect souhaite un rendez-vous sur le campus.",
                    OwnerId: createdLead.OwnerId,
                    Subject: "Rendez-vous sur le campus",
                    WhoId: createdLead.Id,
                    Priority: 'High',
                    Status: 'Not Started',
                };
                await salesforce.createTask(presentielTask);
            } else if (presentielValue === 'false') {
                const recontactTask = {
                    ActivityDate: new Date(),
                    Description: "Le prospect souhaite être recontacté.",
                    OwnerId: createdLead.OwnerId,
                    Subject: "Recontacter le prospect",
                    WhoId: createdLead.Id,
                    Priority: 'High',
                    Status: 'Not Started',
                };
                await salesforce.createTask(recontactTask);
            }

            await salesforce.createCampaignMember(campaignMember)
            const externalId = campaignId || utm_id || campaign_id || campaignid;
            if (externalId) {
                await salesforce.createCampaignMemberExternal(campaignMember, externalId)
            }
            reset();
            console.log(redirectUrl);

            setTimeout(() => {
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    window.location.reload();
                }
            }, 2000);
        } catch (error) {
            console.error("error", error);
        }
    }

    let sfParam = eda ? [school, eda] : getRecordTypeName(school);
    useSalesforce(...(Array.isArray(sfParam) ? sfParam : [sfParam]));

    const watchCampus = watch('lead.Campus__c') || [];
    useEffect(() => {
        if (campuses && campuses.length === 1) {
            const campus = campuses[0].value;
            setValue("lead.Campus__c", [campus]);
        } else {
            setValue("lead.Campus__c", []);
        }
    }, [campuses, setValue]);

       useEffect(() => {
        if (!Array.isArray(watchCampus)) {
            setValue('lead.Campus__c', [watchCampus]);
        }
    }, [watchCampus, setValue]);

    return (
        <FormProvider {...methods}>
            <DevTool control={control} />

            <Grid container spacing={2} style={{ ...theme }}>

                {salutationRequired && (
                    <Grid item xs={12}>
                        <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} />
                    </Grid>
                )}
                {firstNameRequired && (
                    <Grid item xs={12}>
                        <InputText control={control} label="Prénom"
                            name="lead.FirstName" required={firstNameRequired} />
                    </Grid>
                )}
                {lastNameRequired && (
                    <Grid item xs={12}>
                        <InputText control={control} label="Nom"
                            name="lead.LastName" required={lastNameRequired} />
                    </Grid>

                )}
                {emailRequired && (
                    <Grid item xs={12}>
                        <Email control={control} label="Email"
                            name="lead.Email" required={emailRequired} />
                    </Grid>
                )}
                {phoneNumberRequired && (
                    <Grid item xs={12}>
                        <PhoneNumber control={control} label="Numéro de téléphone"
                            name="lead.MobilePhone" required={phoneNumberRequired} />
                    </Grid>
                )}
                {(academicLevelRequired && academics.length > 1) && (
                    <Grid item xs={12}>
                        <AcademicLevel
                            school={school}
                            academics={school ? [] : academics}
                            options={getAcademicLevels(school, form).length > 0 ? getAcademicLevels(school, form) : academics.map(academic => ({ value: academic, label: academic }))}
                            section={false}
                            specialites={false}
                            required={academicLevelRequired}
                        />
                    </Grid>
                )}
                {(campusRequired && campuses.length > 1) && (
                    <Grid item xs={12}>
                        {isMultiCampus ? (
                            <MultiCampus options={campusOptions} required={campusRequired} />
                        ) : (
                            <Campus label="Campus souhaité" options={campusOptions} required={campusRequired} />
                        )}
                    </Grid>
                )}
                {formationRequired && (
                    school === "e-artsup" ? (
                        <Cursus ecole="e-artsup" label="Programme d'intérêt" />
                    ) : (
                        <Formations school={school} />
                    )
                )}
                {postalCodeRequired && (
                    <Grid item xs={12}>
                        <InputText control={control} label="Code postal" name="lead.PostalCode" />
                    </Grid>
                )}
                {presentielRequired && (
                    <Presentiel />
                )}
                {contactConsent && (
                    <Grid item xs={12}>
                        <ConsentementMultiple />
                    </Grid>
                )}
                {consentRequired && (
                    <Grid item xs={12}>
                        <Consentement school={school} form={form} />
                    </Grid>
                )}
                <RequiredFields />
                <Grid item xs={12} display="flex" justifyContent="center">
                  {/* <Grid item xs={1}> */}
                    <Submit onSubmit={handleSubmit(onSubmit)} showEndIcon={false}
                    />
                  {/* </Grid> */}
                </Grid>
                {(legalRequired && form === "Documentation") && (
                    <MentionsLegales form={form} school={school} />
                )}
                {showConfirmation && (
                    <Grid item xs={12}>
                        <p >Votre formulaire a été soumis avec succès!</p>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    );
};

Form.propTypes = {
    division: PropTypes.string,
    campuses: PropTypes.array,
    school: PropTypes.string,
    lastForm: PropTypes.string,
    leadSource: PropTypes.string,
    defaultCampaignId: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    subject: PropTypes.string,
    form: PropTypes.string,
    recordTypeName: PropTypes.string,
    academics: PropTypes.array,
    theme: PropTypes.object,
    firstNameRequired: PropTypes.bool,
    lastNameRequired: PropTypes.bool,
    emailRequired: PropTypes.bool,
    phoneNumberRequired: PropTypes.bool,
    academicLevelRequired: PropTypes.bool,
    campusRequired: PropTypes.bool,
    postalCodeRequired: PropTypes.bool,
    presentielRequired: PropTypes.bool,
    contactConsent: PropTypes.bool,
    consentRequired: PropTypes.bool,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    hover: PropTypes.string,
    textColor: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    selectHover: PropTypes.string,
    elevation: PropTypes.number,
    borderRadius: PropTypes.number,
    shadow: PropTypes.string,
}

export default Form;



