import PropTypes from "prop-types"
import { LoadingButton } from "@mui/lab"
import { Grid } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import { useFormContext } from "react-hook-form"

const Submit = ({ label = "Valider", onSubmit, showEndIcon = true }) => {

  const { formState: { isSubmitting }, handleSubmit } = useFormContext()

  return (
    <Grid item xs={12}>
      <LoadingButton color="primary"
        endIcon={showEndIcon ? <SendIcon /> : null}
        loading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
        loadingPosition="end"
        variant="contained">{label}
      </LoadingButton>
    </Grid>
  )
}

Submit.propTypes = {
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  showEndIcon: PropTypes.bool
}

export default Submit
